import React, { useState, SyntheticEvent } from 'react';
import { Images } from '../../images';
import { Clickable, ClickableVariant } from '../Clickable';
import { Image } from '../Image';
import { Input, Password } from '../Input';
import { signIn } from '../../actions/accountActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getStatusOfSignIn } from '../../selectors';
import { isPending, isRejected } from '../../utils';
import classes from './SignInForm.module.scss';
import { Form } from './Form';
import { GoogleSignIn } from '../GoogleSignIn/GoogleSignIn';
import { Divider } from '@material-ui/core';
import { signInSchema } from '../../utils/validationSchemas'; // Import Yup schema
import * as Yup from 'yup';

export function SignInForm() {
  const dispatch = useAppDispatch();

  const status = useAppSelector(getStatusOfSignIn);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);

  // State for form values and errors
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formError, setFormError] = useState('');

  // Handle input changes
  const handleChange = (field: string) => (value: string) => {
    setFormValues(prev => ({ ...prev, [field]: value }));
    setErrors(prev => ({ ...prev, [field]: '' })); // Clear field errors on change
  };

  // Handle form submission
  const onSubmit = async (evt: SyntheticEvent) => {
    evt.preventDefault();

    try {
      // Validate form values with Yup
      await signInSchema.validate(formValues, { abortEarly: false });
      setErrors({});
      setFormError('');

      // Proceed with sign-in logic
      await signIn(dispatch, formValues.email, formValues.password);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const fieldErrors: { [key: string]: string } = {};
        err.inner.forEach(error => {
          if (error.path) {
            fieldErrors[error.path] = error.message;
          }
        });
        setErrors(fieldErrors);
        setFormError('Please correct the highlighted fields to continue.');
      } else {
        console.error('Unexpected error:', err);
        setFormError('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <div className={classes.SignInForm}>
      <div className="flex justify-end">
        <Image
          src={Images.GATHERLY_LOGO.src}
          alt={Images.GATHERLY_LOGO.alt}
          className={classes.logo}
        />
      </div>
      <h2 className="subtitle mt_5 mb1_5">Sign in</h2>
      <div className="flex flex-col mb1 align-center justify-center">
        <GoogleSignIn className="mb1" />
        <Divider />
      </div>
      <Form className="flex flex-col gap-1" onSubmit={onSubmit}>
        <Input
          data-cy="email"
          autoComplete="email"
          label="Email Address"
          name="email"
          onChange={e => handleChange('email')(e.target.value)}
          value={formValues.email}
          error={errors.email}
          isDisabled={isMakingRequest}
          isRequired
        />
        <Password
          data-cy="password"
          autoComplete="current-password"
          label="Password"
          name="password"
          onChange={e => handleChange('password')(e.target.value)}
          value={formValues.password}
          error={errors.password}
          isDisabled={isMakingRequest}
          isRequired
        />
        <Clickable
          data-cy="sign-in-submit"
          className="mt_75"
          isSubmit
          isDisabled={isMakingRequest}
          label={isMakingRequest ? 'SIGNING IN...' : 'SUBMIT'}
          variant={ClickableVariant.BUTTON_PRIMARY}
        />
      </Form>
      <div className="flex flex-1 flex-col gap-1 items-center pt1">
        {hasError && (
          <p className="color-red small text-center">
            Unable to log in. Please check your <br /> username &amp; password
            and try again.
          </p>
        )}
        {formError && (
          <p className="color-red small text-center">{formError}</p>
        )}
        <Clickable variant={ClickableVariant.LINK} to="/sign-up">
          Don't have an account? Sign Up!
        </Clickable>
        <Clickable variant={ClickableVariant.LINK} to="/forgot-password">
          Forgot Password?
        </Clickable>
      </div>
    </div>
  );
}
