import React, { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { generate as shortUuid } from 'short-uuid';

import { useLocation } from 'wouter';
import { Clickable, ClickableVariant } from '../../components/Clickable';
import { EventCard } from '../../components/EventCard';
import { Footer } from '../../components/Footer';
import { InfiniteScroller } from '../../components/InfiniteScroller';
import { Status } from '../../components/Status';
import { Toolbar, BulkEdit } from '../../components/Toolbar';
// import { TicketsIndicator } from '../../components/TicketsIndicator';
import { MINUTE } from '../../config';
import { c, getEventServerStatus, isPending, isRejected } from '../../utils';
import {
  fetchUpcomingEvents,
  setOverlay,
  OverlayType,
  setBulkEdit,
} from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  allEvents,
  getStatusOfGetUpcomingEvents,
  isBulkEditing,
  lastEventLoaded,
  shouldLoadMoreEvents,
} from '../../selectors';
import { GatherlyEventServerStatus } from '../../enums';
import classes from './EventsPage.module.scss';
import { IGatherlyEvent } from '@gatherly/types';

const selectedEventIdSet: Set<string> = new Set();

export function EventsPage() {
  const dispatch = useAppDispatch();
  const [, setLocation] = useLocation();

  const events = useAppSelector(allEvents);
  const status = useAppSelector(getStatusOfGetUpcomingEvents);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);
  const hasMore = useAppSelector(shouldLoadMoreEvents);
  const lastEvent = useAppSelector(lastEventLoaded);
  const hasEvents = !!events?.length;
  const [selectedEventIds, setSelectedEventIds] = useState<string[] | []>([]);
  const isEditing: boolean = useAppSelector(isBulkEditing);

  useEffect(() => {
    fetchUpcomingEvents(dispatch);
    return () => selectedEventIdSet.clear();
  }, []);

  useEffect(() => {
    if (!isEditing) {
      selectedEventIdSet.clear();
      setSelectedEventIds([]);
    }
  }, [isEditing]);

  const onClickNewEvent = useCallback(() => {
    setOverlay(dispatch, OverlayType.NEW_EVENT, {
      allowDismiss: false,
    });
  }, []);

  const handleLoadMore = useCallback(() => {
    fetchUpcomingEvents(dispatch, lastEvent);
  }, [lastEvent]);

  // Force event cards to recalculate status pill every minute
  const [refreshKey, setRefreshKey] = useState(shortUuid());
  useInterval(() => {
    setRefreshKey(shortUuid());
  }, MINUTE);

  const handleCancelBulkEdit = useCallback(() => {
    setBulkEdit(dispatch, false);
    selectedEventIdSet.clear();
    setSelectedEventIds([]);
  }, []);

  const handleBulkDelete = useCallback(() => {
    setOverlay(dispatch, OverlayType.DELETE_EVENT, {
      eventIds: selectedEventIds,
    });
  }, [selectedEventIds, dispatch]);

  const toggleSelectedEventCard = useCallback(
    (eventId: string) => {
      const event = events.find(
        (event: IGatherlyEvent) => event.eventId === eventId,
      );
      const serverStatus = getEventServerStatus(
        event?.startTime,
        event?.stopTime,
      );

      if (serverStatus !== GatherlyEventServerStatus.PRELAUNCH) {
        return;
      }
      if (selectedEventIdSet.has(eventId)) {
        selectedEventIdSet.delete(eventId);
      } else {
        selectedEventIdSet.add(eventId);
      }
      setSelectedEventIds([...selectedEventIdSet]);
    },
    [events],
  );

  const handleClickEventCard = useCallback(
    (eventId: string) => {
      if (isEditing) {
        toggleSelectedEventCard(eventId);
      } else {
        setLocation(`/events/${eventId}`);
      }
    },
    [isEditing],
  );

  return (
    <div
      className={c(
        classes.EventsPage,
        'page-inner overflow-hidden bg-color-gradient-faded',
      )}
    >
      <Toolbar title="Events" classNames="bg-frosted" />
      <div
        className={c(
          classes.list,
          'flex flex-1 flex-col gap-1 justify-between page-inner__content',
        )}
      >
        <div className="flex flex-col md:flex-row-reverse gap-1">
          {/* <div className="flex flex-1 gap-1 justify-end md:pl2 md:flex-col md:justify-start md:items-end"> */}
          <div className="flex flex-1 gap-1">
            <Clickable
              data-cy="create-event"
              onClick={onClickNewEvent}
              variant={ClickableVariant.BUTTON_TERTIARY}
            >
              Create event
            </Clickable>
            <BulkEdit
              editLabel="Delete events"
              enableEditing={() => setBulkEdit(dispatch, true)}
              isEditing={isEditing}
              onCancel={handleCancelBulkEdit}
              onDelete={handleBulkDelete}
              selectedCount={selectedEventIds.length}
            />
          </div>
          {/* <TicketsIndicator /> */}
        </div>

        <div
          className={c(
            'flex flex-col gap-1',
            !hasEvents && 'flex-1 mb8 items-center justify-center',
          )}
        >
          <InfiniteScroller
            className="flex flex-col gap-1"
            hasMore={hasMore}
            onLoad={handleLoadMore}
          >
            {events.map(event => (
              <EventCard
                event={event}
                onClick={() => handleClickEventCard(event.eventId)}
                isSelected={selectedEventIdSet.has(event.eventId)}
                isEditing={isEditing}
                key={`${refreshKey}-${event.eventId}`}
              />
            ))}
          </InfiniteScroller>
          {(isMakingRequest || !hasEvents) && (
            <Status
              className="color-shade-80"
              withLoader={isMakingRequest}
              message={
                isMakingRequest ? (
                  `Loading${hasEvents ? ' more' : ''} events...`
                ) : hasError ? (
                  'Error retrieving events!'
                ) : (
                  <span>
                    No events yet.{' '}
                    <Clickable
                      data-cy="create-event"
                      onClick={onClickNewEvent}
                      variant={ClickableVariant.BUTTON_TERTIARY}
                    >
                      Create my first event!
                    </Clickable>
                  </span>
                )
              }
            />
          )}
          {!hasMore && !isMakingRequest && hasEvents && (
            <div className="flex flex-col items-center text-center gap-1 color-shade-80 my4">
              <p className="subheading">That's it!</p>
              <p className="body">
                You have created {events.length} Gatherly event
                {events.length > 1 ? 's' : ''} so far.
                <br />
                Make a new one?
              </p>
              <Clickable
                onClick={onClickNewEvent}
                variant={ClickableVariant.BUTTON_TERTIARY}
              >
                New Event
              </Clickable>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
