import {
  Button,
  ButtonVariant,
  DraggableSessionCard,
  classnames,
} from '@gatherly/lib';
import { Clickable, ClickableVariant } from '../../Clickable';
import { IAgendaSession, IGatherlyEvent } from '@gatherly/types';
import { OverlayType, setOverlay, updateEventConfig } from '../../../actions';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { humanizeShortHand, isPending } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import { DraggableList } from '../../DraggableList';
import { EventFormFooter } from './EventFormFooter';
import { EventFormHeader } from './EventFormHeader';
import classes from './EventAgendaForm.module.scss';
import { getStatusOfUpdateEvent } from '../../../selectors';
import isEqual from 'lodash.isequal';
import { generate as shortUuid } from 'short-uuid';
import { trackSessionDeleted } from '../../../libs/trackingLib';
import { MINUTE } from '../../../config';

type Props = {
  event: IGatherlyEvent;
  isDisabled: boolean;
  setHasChanges: (hasChanges: boolean) => void;
};

function getChanges(sessions: IAgendaSession[], agenda: IAgendaSession[]) {
  const didUpdate = !isEqual(sessions, agenda);
  return {
    sessions,
    didUpdate,
  };
}

export function EventAgendaForm({ event, isDisabled, setHasChanges }: Props) {
  const {
    eventId,
    config: { agenda },
  } = event;
  const dispatch = useAppDispatch();
  const status = useAppSelector(getStatusOfUpdateEvent(eventId));
  const [refreshKey, setRefreshKey] = useState(shortUuid());
  const [sessions, setSessions] = useState([...(agenda ?? [])]);
  const [hasInlineEditChanges, setHasInlineEditChanges] = useState(false);

  const totalLength = sessions.reduce((acc, item) => acc + item.length, 0);

  const sessionTitle =
    totalLength === 0 ? 'empty' : humanizeShortHand(totalLength * MINUTE);

  useEffect(() => {
    setSessions([...(agenda ?? [])]);
  }, [agenda]);

  // onChange Handlers
  const hasChanges = useMemo(() => {
    const { didUpdate } = getChanges(sessions, agenda ?? []);
    setHasChanges(didUpdate);
    return didUpdate;
  }, [sessions, setHasChanges, agenda]);

  // Click Handlers
  const onSubmit = async (evt?: SyntheticEvent) => {
    if (evt) evt.preventDefault();
    const { sessions: updatedSessions, didUpdate } = getChanges(
      sessions,
      agenda ?? [],
    );
    const deleteCount = (agenda ?? []).length - updatedSessions.length;

    if (!didUpdate) return;

    await updateEventConfig(dispatch, event, {
      agenda: updatedSessions,
    });

    setHasInlineEditChanges(false);
    if (deleteCount > 0) {
      for (let i = 0; i < deleteCount; i++) {
        trackSessionDeleted(agenda ? agenda.length - i - 1 : 0);
      }
    }
  };

  const onEditInline = (
    id: string,
    value: {
      description?: string | undefined;
      name?: string | undefined;
    },
  ) => {
    const index = sessions.findIndex(session => session.id === id);
    if (index === -1) return;

    const newSessions = [...sessions];
    newSessions[index] = {
      ...newSessions[index],
      ...value,
    };
    setSessions(newSessions);
  };

  const onReorder = useCallback(items => {
    setSessions(items.map(item => item.content));
    setRefreshKey(shortUuid());
  }, []);

  const handleAddSession = (params?: {
    sessionId?: string;
    duplicate?: boolean;
    position?: number;
  }) => {
    setOverlay(dispatch, OverlayType.ADD_SESSION, {
      eventId,
      ...params,
    });
  };

  const renderSessionCard = (provided, snapshot, session, index) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        key={session.id}
        className={classes.container}
      >
        <InsertAgendaSession
          handleAddSession={handleAddSession}
          isDisabled={isDisabled}
          index={index}
        />
        <DraggableSessionCard
          className={classes.card}
          showFullDescription
          showFullTitle
          session={{
            ...session,
            mode: session?.mode?.type ?? undefined,
          }}
          contentEditable
          onInlineEdit={value => onEditInline(session.id, value)}
          setHasChanges={changed => {
            setHasInlineEditChanges(changed);
            setHasChanges(changed);
          }}
          onDelete={
            isDisabled
              ? undefined
              : () => {
                  setSessions(sessions.filter(s => s.id !== session.id));
                }
          }
          onDuplicate={
            isDisabled
              ? undefined
              : () =>
                  handleAddSession({
                    sessionId: session.id,
                    duplicate: true,
                    position: index + 1,
                  })
          }
          onEdit={
            isDisabled
              ? undefined
              : () =>
                  handleAddSession({
                    sessionId: session.id,
                    duplicate: false,
                    position: index,
                  })
          }
          disabled={isDisabled}
        />
      </div>
    );
  };

  return (
    <div
      className={classnames(
        'flex flex-1 flex-col gap-1',
        classes.EventAgendaForm,
      )}
    >
      <EventFormHeader
        title={'Agenda'}
        eventId={eventId}
        isDisabled={isDisabled}
      >
        <p className="color-shade-80 body">
          If you want to structure your event, you can use our Agenda. The
          Agenda is broken up into Sessions which you can configure and
          customize.
          <br />
          <Clickable
            to={`https://www.gatherly.io/help-center/what-is-the-agenda%3F`}
            variant={ClickableVariant.LINK}
          >
            Learn more
          </Clickable>
        </p>
      </EventFormHeader>
      <div className="flex flex-col gap-1 body md:pl2">
        <div>Sessions ({sessionTitle})</div>
        <DraggableList
          id={refreshKey}
          items={sessions.map(session => ({
            id: session.id,
            content: session,
          }))}
          renderItem={renderSessionCard}
          onReorder={onReorder}
          isDisabled={isDisabled}
          className={classes.cardWrapper}
        />
        <div className={classes.addSession}>
          <Button
            variant={ButtonVariant.BUTTON_TERTIARY}
            onClick={() => handleAddSession()}
            disabled={isDisabled}
          >
            + Add Session
          </Button>
        </div>

        <EventFormFooter
          eventId={eventId}
          isDisabled={isDisabled}
          hasChanges={hasChanges || hasInlineEditChanges}
          isMakingRequest={isPending(status)}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}

interface InsertProps {
  index: number;
  isDisabled: boolean;
  handleAddSession: (params?: {
    sessionId?: string;
    duplicate?: boolean;
    position?: number;
  }) => void;
}
export const InsertAgendaSession = ({
  index,
  handleAddSession,
  isDisabled,
}: InsertProps) => {
  if (index === 0) return null;

  return (
    <div
      className={classnames(
        classes.InsertAgendaSession,
        isDisabled && classes.disabled,
      )}
    >
      <div
        className={classnames(classes.content)}
        onClick={() => handleAddSession({ position: index })}
      >
        <div className={classes.border}></div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.insertButton}
            variant={ButtonVariant.BUTTON_TERTIARY}
          >
            + Insert
          </Button>
        </div>
      </div>
    </div>
  );
};
