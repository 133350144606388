const STAGE = process.env.REACT_APP_STAGE;
export const ADMIN_FEATURES_LIST_URL =
  'https://www.gatherly.io/help-center/what-can-admins-on-gatherly-do%3F';
export const BROADCAST_RECORDINGS_HELP_URL =
  'https://www.gatherly.io/help-center/%E2%81%A0how-to-broadcast-your-video-to-the-event-and%2For-floor';
export const DEFAULT_BROCHURE_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/brochure-pdf/default-brochure.pdf';
export const DEFAULT_MAP_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-maps/_Blank.jpeg';
export const LANDING_PAGE_INSTRUCTIONS_URL =
  'https://www.youtube.com/@GatherlyVirtualEventsInc/playlists';
export const MAP_TEMPLATE_URL =
  'https://docs.google.com/presentation/u/3/d/11c209mkLwMSKfW-OOQ6JpY7YuIjn0HQmUynEnNRSSOQ/copy#slide=id.g9072c99268_6_0';
export const SALES_CONTACT_URL =
  'https://www.gatherly.io/meet-an-event-planner';

export const SALES_PLAN_UPGRADE_URL =
  'https://www.gatherly.io/meet-an-event-planner';

const defaultBucket =
  STAGE === 'prod' ? 's3betterdmv53458-production' : 's3betterdmv235349-dev';
export const UGC_S3_BUCKET = process.env.UGC_S3_BUCKET || defaultBucket;
export const UGC_S3_BUCKET_URL = `https://${UGC_S3_BUCKET}.s3.us-east-2.amazonaws.com/`;

export const BANNERS_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-banners/';
export const MAP_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-maps/';
export const CUSTOMER_SERVICES_AGREEMENT_URL =
  'https://www.gatherly.io/terms-and-conditions';
export const WORLD_MAP_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/location/world-countries.json';
export const BOOTHS_HELP_URL =
  'https://www.gatherly.io/help-center/edit-booth-content-during-an-event';
export const DEFAULT_BOOTH_BACKGROUND_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-booth-backgrounds/1.png';
export const DEFAULT_GAME_BACKGROUND_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-game-backgrounds/_Ping+Pong.png';
export const BOOTH_BACKGROUND_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-booth-backgrounds/';
export const GAME_BACKGROUND_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-game-backgrounds/';
export const GAME_LOGO_BASEURL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-game-logos/';
