import React, { useEffect } from 'react';
// import { useLocation } from 'wouter';

import { isPending, isRejected } from '../../utils';
import { findOrCreateUser } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  currentUser,
  // currentUserPermissions,
  getStatusOfGetUser,
} from '../../selectors';
import { Toolbar } from '../../components/Toolbar';
// import { Tickets } from '../../components/Tickets';
import { Clickable, ClickableVariant } from '../../components/Clickable';
// import { PlanBadge, PlanDetails } from '../../components/AccountPlan';

import UserDetailsForm from '../../components/forms/UserDetailsForm';

const PAGE_TITLE = 'Account';

export function AccountPage() {
  const dispatch = useAppDispatch();

  const user = useAppSelector(currentUser);
  const status = useAppSelector(getStatusOfGetUser);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);

  useEffect(() => {
    findOrCreateUser(dispatch);
  }, []);

  const fallback = isMakingRequest ? (
    <p className="App-error">Loading...</p>
  ) : (
    <p className="App-error">User not found.</p>
  );

  return (
    <div className="page-inner bg-color-gradient-faded">
      <Toolbar title={PAGE_TITLE} classNames="bg-frosted" />
      <div className="flex-1 flex flex-col lg:flex-row p1 gap-1 page-inner__content">
        {user ? (
          <>
            <div className="flex lg:col-4 xl:col-5 flex-col gap-2 bg-color-white shadow-sm br_25 p2">
              <h3 className="color-shade-80 body bold">My Profile</h3>
              <UserDetailsForm {...user} />
              <div style={{ marginTop: '1rem' }}>
                <Clickable
                  variant={ClickableVariant.BUTTON_SECONDARY}
                  label="Manage Subscription"
                  onClick={() =>
                    window.open(
                      'https://billing.stripe.com/p/login/cN2dSh9H09YY7LO000',
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }
                  style={{
                    width: 'auto', // Ensures it retains its current length
                    //padding: '0.5rem 1rem', // Matches the Save button's padding
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          fallback
        )}
        {hasError && (
          <p className="App-error">Error retrieving current user!</p>
        )}
      </div>
    </div>
  );
}
