import axios from 'axios';

const JSON_URL =
  'https://event-manager-urls.s3.us-east-2.amazonaws.com/em-links.json';

let cachedData: any = null; // Cache the data to avoid repeated network calls

export async function fetchLinks(): Promise<any> {
  try {
    if (cachedData) {
      // Return cached data if available
      return cachedData;
    }

    const response = await axios.get(JSON_URL);
    cachedData = response.data; // Cache the fetched data
    return cachedData;
  } catch (error) {
    console.error('Error fetching links:', error);
    throw new Error('Unable to load links.');
  }
}

// Helper function to get a specific section of the JSON
export async function getSection(sectionName: string): Promise<any[]> {
  try {
    const data = await fetchLinks();
    return data[sectionName] || [];
  } catch (error) {
    console.error(`Error fetching section "${sectionName}":`, error);
    throw new Error(`Unable to load section "${sectionName}".`);
  }
}

// Helper function to get a specific item by description
export async function getItem(
  sectionName: string,
  description: string,
): Promise<string | null> {
  try {
    const section = await getSection(sectionName);
    const item = section.find(
      (entry: any) => entry.description === description,
    );
    return item?.url || null;
  } catch (error) {
    console.error(
      `Error fetching item "${description}" from "${sectionName}":`,
      error,
    );
    throw new Error(`Unable to load item "${description}".`);
  }
}
