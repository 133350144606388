import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Clickable, ClickableVariant } from '../Clickable';
import { Input, Password } from '../Input';
import { signUp, setIdle } from '../../actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getStatusOfSignUp } from '../../selectors';
import { isPending, isRejected } from '../../utils';
import { Form } from './Form';
import { GoogleSignIn } from '../GoogleSignIn/GoogleSignIn';
import { Divider } from '@material-ui/core';
import { signUpSchema } from '../../utils/validationSchemas';
import * as Yup from 'yup';

export function SignUpForm() {
  const dispatch = useAppDispatch();

  // Reset sign-up status on component mount
  useEffect(() => {
    setIdle(dispatch, 'signUp');
  }, [dispatch]);

  const status = useAppSelector(getStatusOfSignUp);
  const isMakingRequest = isPending(status);
  const hasError = isRejected(status);

  // Form state
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formError, setFormError] = useState('');

  // Handle input changes
  const handleChange = (field: string) => (value: string) => {
    setFormValues(prev => ({ ...prev, [field]: value }));
    setErrors(prev => ({ ...prev, [field]: '' })); // Clear errors on change
  };

  // Handle form submission
  const onSubmit = async (evt: SyntheticEvent) => {
    evt.preventDefault();

    try {
      // Validate form data with Yup
      await signUpSchema.validate(formValues, { abortEarly: false });
      setErrors({});
      setFormError('');

      // Submit valid data
      const response = await signUp(dispatch, formValues);
      if (response?.errorMessage) {
        setFormError(response.errorMessage);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const fieldErrors: { [key: string]: string } = {};
        err.inner.forEach(error => {
          if (error.path) {
            fieldErrors[error.path] = error.message;
          }
        });
        setErrors(fieldErrors);
        setFormError('Please correct the highlighted fields to continue.');
      } else {
        console.error('Unexpected error:', err);
        setFormError('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <Form className="flex flex-col gap-1" autoComplete="on" onSubmit={onSubmit}>
      <div className="flex flex-col mb1 align-center justify-center">
        <GoogleSignIn className="mb1" label="Sign up with Google" />
        <Divider />
      </div>
      <div className="flex gap-1">
        <Input
          label="First Name"
          name="firstName"
          value={formValues.firstName}
          onChange={e => handleChange('firstName')(e.target.value)}
          error={errors.firstName}
          isDisabled={isMakingRequest}
          isRequired
        />
        <Input
          label="Last Name"
          name="lastName"
          value={formValues.lastName}
          onChange={e => handleChange('lastName')(e.target.value)}
          error={errors.lastName}
          isDisabled={isMakingRequest}
          isRequired
        />
      </div>
      <Input
        label="Company"
        name="company"
        value={formValues.company}
        onChange={e => handleChange('company')(e.target.value)}
        error={errors.company}
        isDisabled={isMakingRequest}
      />
      <Input
        label="Email"
        name="email"
        value={formValues.email}
        onChange={e => handleChange('email')(e.target.value)}
        error={errors.email}
        isDisabled={isMakingRequest}
        isRequired
      />
      <Password
        label="Password"
        name="password"
        value={formValues.password}
        onChange={e => handleChange('password')(e.target.value)}
        error={errors.password}
        isDisabled={isMakingRequest}
        isRequired
      />
      {formError && (
        <p className="color-red small mt1 text-center">{formError}</p>
      )}
      {hasError && (
        <p className="color-red small mt1 text-center">
          Sorry, we were unable to create your account. Please try again.
        </p>
      )}
      <div className="flex justify-center">
        <Clickable
          isSubmit
          isDisabled={isMakingRequest}
          label={isMakingRequest ? 'SIGNING YOU UP...' : 'SIGN UP'}
          variant={ClickableVariant.BUTTON_PRIMARY}
        />
      </div>
      <div className="flex flex-1 flex-col items-center">
        <Clickable variant={ClickableVariant.LINK} className="mt_5" to="/">
          Already have an account? Sign in.
        </Clickable>
      </div>
    </Form>
  );
}
