import React, { useEffect, useState } from 'react';
import { Toolbar } from '../../components/Toolbar';
import { ResourceCard, ResourceCardProps } from './ResourceCard';
import { fetchLinks } from '../../utils/fetchLinks'; // Assuming you created this utility

const PAGE_TITLE = 'Resources';

export function ResourcesPage() {
  const [resources, setResources] = useState<ResourceCardProps[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function loadResources() {
      try {
        const data = await fetchLinks();
        const jsonResources = data.resourcesPage; // Extract resourcesPage from JSON

        // Map fetched JSON data to your resources format
        const updatedResources: ResourceCardProps[] = [
          {
            type: '', //'Video',
            title: 'Overview Video',
            image:
              'https://poormanvr.s3.us-east-2.amazonaws.com/Gatherly_Overview_Video.gif',
            description:
              'Watch our overview video to understand how Gatherly might help you with your next online event.',
            ctaLabel: 'Watch Video',
            ctaLocation: jsonResources.find(
              (item: any) => item.description === 'Overview Video',
            )?.url,
          },
          {
            type: '', //'Guide',
            title: 'Map Customization',
            image:
              'https://poormanvr.s3.us-east-2.amazonaws.com/Gatherly_Edit_Floors.gif',
            description:
              'Create your own custom maps with our easy-to-use template! It includes a tutorial video to show you how!',
            ctaLabel: 'Get started',
            ctaLocation: jsonResources.find(
              (item: any) => item.description === 'Map Customization',
            )?.url,
          },
          {
            type: '', //'Support',
            title: 'Help Center',
            image:
              'https://poormanvr.s3.us-east-2.amazonaws.com/Gatherly_Help_Center.gif',
            description:
              'Learn all about Gatherly in our comprehensive guides designed for event hosts like you.',
            ctaLabel: 'View Guides',
            ctaLocation: jsonResources.find(
              (item: any) => item.description === 'Help Center',
            )?.url,
          },
          {
            type: '', //'Videos',
            title: 'Tutorial Playlist',
            image:
              'https://poormanvr.s3.us-east-2.amazonaws.com/Gatherly_Video_Playlist.gif',
            description:
              'This playlist covers all the important features and is great prep for your guests.',
            ctaLabel: 'View Playlist',
            ctaLocation: jsonResources.find(
              (item: any) => item.description === 'Tutorial Playlist',
            )?.url,
          },
          {
            type: '', //'How Can We Help',
            title: 'How Can We Help?',
            image:
              'https://poormanvr.s3.us-east-2.amazonaws.com/tutorial-user-katara.gif',
            description:
              'Chat with a member of the Gatherly team about your virtual events.',
            ctaLabel: 'Contact Us',
            ctaLocation: jsonResources.find(
              (item: any) => item.description === 'How Can We Help',
            )?.url,
          },
        ];

        setResources(updatedResources);
      } catch (err) {
        console.error('Failed to fetch resources:', err);
        setError('Failed to load resources. Please try again later.');
      }
    }

    loadResources();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!resources.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-inner">
      <Toolbar title={PAGE_TITLE} classNames="bg-frosted" />
      <div className="bg-color-gradient-faded page-inner__content flex-1">
        <div className="flex flex-row flex-wrap gap-2 pt1 pb8">
          {resources.map((resource, index) => (
            <ResourceCard {...resource} key={`resource-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
}

/*
  {
    type: 'Template',
    title: 'Brochure Customization',
    image:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/customBrochure.svg',
    description:
      'Not sure how to communicate your event info? Use our template as a starting point.',
    ctaLabel: 'Download Template',
    ctaLocation:
      'https://gatherlyio-website-assets.s3.us-east-2.amazonaws.com/betterDMV/downloads/Gatherly+Brochure+Customization.pptx',
  },
  */

/*
  {
    type: 'Tech',
    title: 'Check Compatibility',
    image:
      'https://poormanvr.s3.us-east-2.amazonaws.com/tutorial-user-katara.gif',
    description:
      'Send guests here to check their device and network compatibility with Gatherly.',
    ctaLabel: 'Visit Hub',
    ctaLocation: 'https://check.gatherly.io/',
  },
  */
