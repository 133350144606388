/* eslint-disable prettier/prettier */
import { DateTime } from 'luxon';
import validators from 'validator';
import { ValidationsList } from '../components/Validations';
import {
  EVENT_ALIAS_SUFFIX,
  MIN_EVENT_CAPACITY,
  MAX_EVENT_CAPACITY,
  MIN_EVENT_DURATION,
  SERVER_LAUNCH_MINUTES,
} from '../config';
import { parseDatePickerDateAsJs, getDurationMinutes } from './date';
import { getByteLength, kebabify, pluralize } from './misc';

export type FormField<T> = {
  value: T;
  error: string;
  validations?: ValidationsList;
};

export function getFormField<T>(initial: T): FormField<T> {
  return {
    value: initial,
    error: '',
  };
}

export function getOnChangeFormField<T>(
  validate: (value: any) => FormField<T>,
  set: (validatedField: FormField<T>) => void,
): (value: T) => FormField<T> {
  return value => {
    const validatedField = validate(value);
    set(validatedField);
    return validatedField;
  };
}

export function hasValidationError(...fields: FormField<any>[]) {
  return fields.some(field => field.error);
}

export function validateString(
  value: string,
  label: string,
  isRequired = true,
  characterLimit = 0,
): FormField<string> {
  if (characterLimit && value.length > characterLimit) {
    return {
      value: value,
      error: `${label} cannot exceed ${characterLimit} characters`,
    };
  }

  return {
    value: value,
    error: !!value || !isRequired ? '' : `${label} cannot be empty`,
  };
}

export function validateUniqueString(
  value: string,
  label: string,
  reservedList: string[],
): FormField<string> {
  let error = '';
  if (!value) {
    error = `${label} cannot be empty`;
  } else if (reservedList.includes(value)) {
    error = `${label} is already taken`;
  }
  return { value, error };
}

export function validateAbsNumber(
  value: number,
  label: string,
  isRequired = true,
): FormField<number> {
  return {
    value: Math.abs(value),
    error: !!value || !isRequired ? '' : `${label} cannot be ${value}`,
  };
}

/*
  Password validation must fit Cognito requirements
  * between 6 and 99 characters
  * requires upper/lowercase letters, number, special character
  * special characters: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ `
  
  Event Manager restrictions are

  * between 8 and 32 characters (maxLength HTML input constraint)
  * requires upper/lowercase letters, number, special character
  * fewer pecial characters: ^ $ * ? ! @ # % & _ ~

*/
const lengthRegExp = RegExp(/.{8,}/);
const lettersRegExp = RegExp(/(?=.*[a-z])(?=.*[A-Z])/);
const specialCharacterRegExp = RegExp(/[\^$*?!@#%&_~]/);
const numberRegExp = RegExp(/\d/);

const conditons: Array<[string, RegExp]> = [
  ['at least 8 characters', lengthRegExp],
  ['upper and lower case letters', lettersRegExp],
  ['1 number', numberRegExp],
  ['1 special character (^$*?!@#%&_~)', specialCharacterRegExp],
];

export function validatePassword(
  value: string,
  isRequired = true,
): FormField<string> {
  const validations: Array<[string, boolean]> = conditons.map(
    ([message, regexp]) => {
      return [message, regexp.test(value)];
    },
  );

  const hasFailedValidations = validations.some(([_, result]) => !result);

  const error = !isRequired || !hasFailedValidations ? '' : 'Weak password';

  return {
    value,
    error,
    validations,
  };
}

export function isValidUrl(value: string | undefined): FormField<string> {
  if (!value) {
    return {
      value: '',
      error: '',
    };
  }

  return {
    value: value,
    error: value && validators.isURL(value) ? '' : `Url is not valid`,
  };
}

function isValidHttpUrl(urlString: string) {
  try {
    const url = new URL(urlString);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
}
const alphanumericRegexp = new RegExp('[a-zA-Zd:]');
export function validateEventAliasPrefix(value: string): FormField<string> {
  const sanitizedValue = kebabify(value.trim().toLocaleLowerCase());
  const hasAlphanumericCharacters = alphanumericRegexp.test(sanitizedValue);
  const isValidUrl = isValidHttpUrl(
    `http://${sanitizedValue}${EVENT_ALIAS_SUFFIX}`,
  );
  const isValid = isValidUrl && hasAlphanumericCharacters;
  return {
    value: hasAlphanumericCharacters ? sanitizedValue : '',
    error: isValid ? '' : 'Url is not valid',
  };
}

export function validateCapacity(
  rawValue: string,
  availableTickets: number,
  ticketsAlreadyPurchased: number,
): FormField<string> {
  const value =
    typeof rawValue === 'string' ? parseInt(rawValue, 10) : rawValue;
  const sanitizedValue = Number.isInteger(value) ? value : MIN_EVENT_CAPACITY;
  let error = '';
  if (sanitizedValue < MIN_EVENT_CAPACITY) {
    error = `Minimum capacity is ${MIN_EVENT_CAPACITY} people`;
  } else if (sanitizedValue - ticketsAlreadyPurchased > availableTickets) {
    // error = `You only have ${availableTickets} tickets. Buy more in the accounts tab.`;
    error = 'Max capacity reached contact info@gatherly.io';
  } else if (sanitizedValue > MAX_EVENT_CAPACITY) {
    error = `Maximum capacity is ${MAX_EVENT_CAPACITY} people. To increase it, contact Gatherly support.`;
  } else if (isNaN(value)) {
    error = 'Invalid capacity';
  }
  return {
    value: rawValue,
    error,
  };
}

export function validateEventDuration(
  startTime: number,
  stopTime: number,
  maxDurationHours: number,
): string {
  try {
    const parsedStart = DateTime.fromMillis(startTime);
    const parsedStop = DateTime.fromMillis(stopTime);

    if (parsedStart === null)
      throw new Error(`cannot parse start ${startTime}`);
    if (parsedStop === null)
      throw new Error(`unable to parse stop ${stopTime}`);

    const durationMinutes = getDurationMinutes(
      parsedStart as DateTime,
      parsedStop as DateTime,
    );
    const unlimitedDuration = maxDurationHours === -1;
    const generateMaxDurationText = (duration: number, extrema = 'Maximum') => {
      const unit =
        extrema === 'Minimum' || maxDurationHours < 1 ? 'minute' : 'hour';
      return `${extrema} event duration is ${pluralize(duration, unit)}`;
    };
    const minsUntilStart = getDurationMinutes(
      DateTime.now(),
      parsedStart as DateTime,
    );

    type TimeValidator = [boolean, () => string];
    const timeValidators: TimeValidator[] = [
      [Number.isNaN(durationMinutes), () => 'Unable to parse duration'],
      [durationMinutes < 0, () => 'Start time has to be before end time'],
      [
        durationMinutes < MIN_EVENT_DURATION,
        () => generateMaxDurationText(MIN_EVENT_DURATION, 'Minimum'),
      ],
      [
        !unlimitedDuration && durationMinutes > maxDurationHours * 60,
        () => generateMaxDurationText(maxDurationHours, 'Maximum'),
      ],
      [
        minsUntilStart < SERVER_LAUNCH_MINUTES,
        () =>
          `Start must be at least ${pluralize(
            SERVER_LAUNCH_MINUTES,
            'minute',
          )} from now`,
      ],
    ];

    const [_, message]: TimeValidator = timeValidators.find(
      ([hasError]) => hasError,
    ) || [true, () => ''];

    return message();
  } catch (e) {
    console.error(e);
    return 'Unable to parse duration';
  }
}

export function validateTimestamp(value: number): FormField<number> {
  try {
    const parsed = parseDatePickerDateAsJs(value);
    if (parsed === null) {
      return {
        value: value,
        error: 'Invalid date',
      };
    } else {
      return {
        value,
        error: '',
      };
    }
  } catch (e) {
    console.error(e);
    return {
      value: value,
      error: 'Unknown error',
    };
  }
}

const emailRexexp = RegExp(/\S+@\S+\.\S+/);
export function validateEmailAddress(
  value: string,
  isRequired = true,
): FormField<string> {
  if (!isRequired && !value) {
    return {
      value,
      error: '',
    };
  }

  const sanitizedValue = value.trim();
  const isValid = emailRexexp.test(value);
  return {
    value: sanitizedValue,
    error: isValid ? '' : 'Invalid email address',
  };
}

export function validateStringMatch(
  value: string,
  matchCandidate: string,
  label: string,
): FormField<string> {
  const sanitizedValue = value.trim();
  const match = sanitizedValue && sanitizedValue === matchCandidate;
  return {
    value: sanitizedValue,
    error: match ? '' : `${label} must match`,
  };
}

export function noValidate<T>(value: T): FormField<T> {
  return {
    value: value,
    error: '',
  };
}

export function validateFile(
  value: File,
  label: string,
  supportedMimeTypes: string[],
  maxSizeInBytes: number,
): string | undefined {
  if (!supportedMimeTypes.includes(value.type)) {
    return 'Image must be a png or jpg';
  }
  const size = getByteLength(value);
  if (size > maxSizeInBytes) {
    return `${label} file is too large`;
  }
}
