import React from 'react';
import { AugmentedGatherlyEvent } from '@eventmanager/types';

import { Clickable, ClickableVariant } from '../Clickable';
import { Pill } from '../Pill';
import { GatherlyEventServerStatus } from '../../enums';
import { c, formatRange, getEventServerStatus, pluralize } from '../../utils';
import { Tooltip } from '../Tooltip';
import classes from './EventCard.module.scss';

type Props = {
  event: AugmentedGatherlyEvent;
  isSelected: boolean;
  isEditing: boolean;
  onClick: () => void;
};

function EventStatusPill({
  className,
  serverStatus,
}: {
  className?: string;
  serverStatus?: string;
}) {
  switch (serverStatus) {
    case GatherlyEventServerStatus.LIVE: {
      return (
        <Pill className={c('bg-color-violet color-white', className)}>
          Live
        </Pill>
      );
    }
    case GatherlyEventServerStatus.LAUNCH: {
      return (
        <Pill className={c('bg-color-logo-blue color-white', className)}>
          Launching...
        </Pill>
      );
    }
    case GatherlyEventServerStatus.PRELAUNCH: {
      return (
        <Pill className={c('bg-color-logo-blue--highlight', className)}>
          Upcoming
        </Pill>
      );
    }
    case GatherlyEventServerStatus.OVER: {
      return (
        <Pill className={c('bg-color-butter', className)}>Event Ended</Pill>
      );
    }
    default:
      return null;
  }
}

export function EventCard({ event, onClick, isSelected, isEditing }: Props) {
  const serverStatus = getEventServerStatus(event.startTime, event.stopTime); // Don't memoize, it should recalc bc its relative to NOW

  return (
    <Tooltip
      content="Only upcoming events can be deleted"
      isDisabled={
        !isEditing || serverStatus === GatherlyEventServerStatus.PRELAUNCH
      }
      followCursor={true}
    >
      <div
        className={c(
          classes.EventCard,
          'bg-color-white color-shade-80 shadow-sm flex flex-1 flex-col gap-_5 br_25 p1_5',
          'hover:shadow-md cursor-pointer',
          isEditing && !isSelected && 'opacity-0_75',
          !isSelected && 'hover:bg-color-shade-10',
        )}
        data-cy="event-card"
        onClick={onClick}
        role="link"
      >
        <div className="flex justify-between">
          <h3 className="heading color-shade-80">{event.name}</h3>
          <EventStatusPill serverStatus={serverStatus} />
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-1 flex-col">
            <p className="flex body">
              {formatRange(event.startTime, event.stopTime)}
            </p>
            <div className="flex flex-wrap detail">
              <span>{pluralize(event.config.floors.length, 'floor')}</span>
              {/* <span className="mx1">&bull;</span> */}
              {/* {event.ticketsUsed ? (
                <span>
                  {event.ticketsUsed} ticket{event.ticketsUsed === 1 ? '' : 's'}{' '}
                  used
                </span>
              ) : (
                <span>{event.capacity} reserved tickets</span>
              )} */}
            </div>
            <div className="mt_5">
              {event.alias ? (
                <Clickable
                  variant={ClickableVariant.LINK}
                  to={`https://${event.alias}`}
                  label={event.alias}
                />
              ) : (
                <p className="color-shade-40">
                  The link for this event has been reused
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
}
