import * as Yup from 'yup';

export const signUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s]+$/, 'First name can only contain letters and spaces')
    .max(50, 'First name cannot exceed 50 characters')
    .required('First name is required'),

  lastName: Yup.string()
    .trim()
    .matches(/^[a-zA-Z\s]+$/, 'Last name can only contain letters and spaces')
    .max(50, 'Last name cannot exceed 50 characters')
    .required('Last name is required'),

  company: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z0-9\s]+$/,
      'Company name can only contain letters, numbers, and spaces',
    )
    .max(50, 'Company name cannot exceed 50 characters'),

  email: Yup.string()
    .email('Invalid email format')
    .max(50, 'Email cannot exceed 49 characters')
    .required('Email is required'),

  password: Yup.string()
    .trim()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Password cannot exceed 50 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(
      /[\^$*?!@#%&_~]/,
      'Password must contain at least one special character (^$*?!@#%&_~)',
    )
    .required('Password is required'),
});

// Sign-In Form Validation Schema
export const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .max(50, 'Email cannot exceed 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .trim()
    .max(50, 'Password cannot exceed 50 characters')
    .required('Password is required'),
});
